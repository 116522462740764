import {
	CanvasTexture,
	FrontSide,
	ImageBitmapLoader,
	Mesh,
	MeshBasicMaterial,
	MeshPhongMaterial,
	PlaneGeometry,
	Quaternion,
	Vector3,
	VideoTexture,
} from 'three';
import { Shapes } from '../geometries/Shapes';
import { LinkSphere } from '../effects/LinkSphere';

const pole = new MeshPhongMaterial({ color: 0x444444 });
const pos = new Vector3();
const bitmapLoader = new ImageBitmapLoader();
bitmapLoader.setOptions({ imageOrientation: 'flipY' });

const quat = new Quaternion(0, 0, 0, 1);
const axisOfRotation = new Vector3(0, 1, 0);
const rad = (deg) => deg * (Math.PI / 180);

function imageToMaterial(imageBitmap) {
	const texture = new CanvasTexture(imageBitmap);

	return new MeshBasicMaterial({ map: texture });
}

function createStructure(origin, imgMaterial, poleHeight, h, w) {
	// Set position
	const { x, y, z } = origin;
	pos.set(x, y, z);

	// Create pole
	pos.add(new Vector3(0, poleHeight / 2, 0));
	Shapes.cylinder(0.5, poleHeight, pos, null, pole, 0);

	// Create board
	pos.add(new Vector3(0, poleHeight / 2 + h / 2, 0));
	const board = Shapes.box(w, h, 1, pos, quat, pole, 0);

	// Create screen and image
	const screenGeo = new PlaneGeometry(w, h);
	const screen = new Mesh(screenGeo, imgMaterial);
	screen.translateZ(0.51);
	board.add(screen);

	return { board, screen };
}

export class Billboard {
	static init() {
		const billboards = [
			{
				// videoUrl: 'duffelbag-promo.mp4',
				img: 'webzep-poster.png',
				width: 10,
				height: 10 / 1.7778,
				poleHeight: 4,
				origin: { x: 25, y: 0, z: -14 },
				rot: -45,
			},
			{
				videoUrl: 'duffelbag-promo.mp4',
				img: 'duffelbag-banner.png',
				width: 10,
				height: 10 / 1.7778,
				poleHeight: 4,
				origin: { x: 45, y: 0, z: -14 },
				rot: -45,
			},
			{
				// videoUrl: 'course-video.mp4',
				img: 'course-image.png',
				width: 10,
				height: 10 / 1.729,
				poleHeight: 4,
				origin: { x: 65, y: 0, z: -14 },
				rot: -45,
			},
			{
				// videoUrl: 'course-video.mp4',
				img: 'ld-poster.png',
				width: 10,
				height: 10 / 1.7778,
				poleHeight: 4,
				origin: { x: -13, y: 0, z: 85 },
				rot: 135,
			},
			{
				// videoUrl: 'course-video.mp4',
				img: 'skyciv-poster.png',
				width: 10,
				height: 10 / 1.7778,
				poleHeight: 4,
				origin: { x: -13, y: 0, z: 65 },
				rot: 135,
			},
			{
				// videoUrl: 'course-video.mp4',
				img: 'pxyz-poster.png',
				width: 10,
				height: 10 / 1.904,
				poleHeight: 4,
				origin: { x: -13, y: 0, z: 45 },
				rot: 135,
			},
			{
				// videoUrl: 'course-video.mp4',
				img: 'grolia-poster.png',
				width: 10,
				height: 10 / 1.904,
				poleHeight: 4,
				origin: { x: -13, y: 0, z: 25 },
				rot: 135,
			},
		];

		billboards.forEach((billboardData) => {
			Billboard.create(billboardData);
		});
	}

	static create({ img, videoUrl, width, height, origin, poleHeight, rot }) {
		return new Promise((resolve, reject) => {
			bitmapLoader.load(
				'images/' + img + '?' + performance.now(),
				function (bitmap) {
					quat.setFromAxisAngle(axisOfRotation, rad(rot));

					const imgMaterial = imageToMaterial(bitmap);

					const { board, screen } = createStructure(
						origin,
						imgMaterial,
						poleHeight,
						height,
						width
					);

					let videoMaterial = null;

					if (videoUrl) {
						// Create video element
						const video = document.createElement('video');
						video.src = `videos/${videoUrl}`;

						video.style.display = 'none';
						document.querySelector('.App').append(video);

						// Create video material
						const videoTexture = new VideoTexture(video);
						videoMaterial = new MeshBasicMaterial({
							map: videoTexture,
							side: FrontSide,
							toneMapped: false,
						});

						screen.name = 'video';
						screen.userData.videoElement = video;
					}

					new LinkSphere({
						parent: board,
						insideCallback: videoUrl
							? (play) => {
									screen.material = videoMaterial ? videoMaterial : imgMaterial;

									const video = screen.userData.videoElement;
									play ? video.play() : video.pause();
							  }
							: null,
					});

					resolve(screen);
				},
				(p) => console.log(p),
				(e) => console.log(e)
			);
		});
	}
}
