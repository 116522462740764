import { useEffect, useRef } from 'react';
import './App.css';
import { World } from './scene/World';
// import { Drone } from './scene/Drone';
import { AP } from './scene/AmmoPhysics';

function App() {
	const sceneRef = useRef(null);

	useEffect(() => {
		if (window.location.pathname.includes('/cv')) {
			window.location.href =
				'https://webzeppelin.notion.site/Steve-Richardson-1db37075f4564643bab49e57f38aa241?pvs=4';
		}

		const setup = async () => {
			if (sceneRef === null) return;

			await AP.init();
			World.init('scene');

			// const floor = new Mesh(
			// 	new BoxGeometry(10, 5, 10),
			// 	new MeshPhongMaterial({ color: 0x111111 })
			// );
			// floor.position.y = -2.5;
			// floor.receiveShadow = true;
			// World.scene.add(floor);
			// AP.addMesh(floor);

			// await Physics.init();
			// Drone.init();
		};
		setup();
	}, [sceneRef]);

	return (
		<div id='scene' ref={sceneRef} className='App'>
			<video
				id='video'
				playsInline
				webkit-playsinline='true'
				muted
				// loop
				autoPlay
				width='320'
				height='240'
				src='videos/duffelbag-promo.mp4'
				style={{ display: 'none' }}
			></video>

			{/* <div
				style={{
					display: 'flex',
					textAlign: 'left',
					flexDirection: 'column',
					position: 'absolute',
					right: '5px',
					bottom: '5px',
					backgroundColor: 'rgba(255,255,255,0.6)',
					padding: '4px',
					borderRadius: '4px',
				}}
			>
				<span>Toggle Camera Mode: F</span>
				<span>Arm: Space</span>
				<span>Move: WASD</span>
				<span>Elevation: JL</span>
				<span>Shoot: I</span>
			</div> */}
		</div>
	);
}

export default App;
