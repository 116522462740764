export const roadGrid = [
	// OUTER
	// top (-z)
	{ dir: '-z', type: 'L', x: 0, z: 0 },
	{ dir: '+x', type: 'I', x: 1, z: 0 },
	{ dir: '+x', type: 'I', x: 2, z: 0 },
	{ dir: '+x', type: 'I', x: 3, z: 0 },
	{ dir: '+x', type: 'I', x: 4, z: 0 },
	{ dir: '+x', type: 'I', x: 5, z: 0 },
	{ dir: '+x', type: 'I', x: 6, z: 0 },
	{ dir: '+x', type: 'I', x: 7, z: 0 },
	{ dir: '+x', type: 'I', x: 8, z: 0 },
	{ dir: '+x', type: 'I', x: 9, z: 0 },
	{ dir: '+x', type: 'I', x: 10, z: 0 },
	{ dir: '+x', type: 'I', x: 11, z: 0 },
	{ dir: '+x', type: 'I', x: 12, z: 0 },
	{ dir: '+x', type: 'I', x: 13, z: 0 },
	{ dir: '+x', type: 'I', x: 14, z: 0 },
	{ dir: '+x', type: 'T', x: 15, z: 0 },
	{ dir: '+x', type: 'I', x: 16, z: 0 },
	{ dir: '+x', type: 'I', x: 17, z: 0 },
	{ dir: '+x', type: 'I', x: 18, z: 0 },
	{ dir: '+x', type: 'I', x: 19, z: 0 },
	{ dir: '+x', type: 'I', x: 20, z: 0 },
	{ dir: '+x', type: 'I', x: 21, z: 0 },
	{ dir: '+x', type: 'I', x: 22, z: 0 },
	{ dir: '+x', type: 'I', x: 23, z: 0 },
	{ dir: '+x', type: 'I', x: 24, z: 0 },
	{ dir: '+x', type: 'I', x: 25, z: 0 },
	{ dir: '+x', type: 'I', x: 26, z: 0 },
	{ dir: '+x', type: 'I', x: 27, z: 0 },
	{ dir: '+x', type: 'I', x: 28, z: 0 },
	{ dir: '+x', type: 'I', x: 29, z: 0 },
	{ dir: '+x', type: 'L', x: 30, z: 0 },
	// right (+x)
	{ dir: '+z', type: 'I', x: 30, z: 1 },
	{ dir: '+z', type: 'I', x: 30, z: 2 },
	{ dir: '+z', type: 'I', x: 30, z: 3 },
	{ dir: '+z', type: 'I', x: 30, z: 4 },
	{ dir: '+z', type: 'I', x: 30, z: 5 },
	{ dir: '+z', type: 'I', x: 30, z: 6 },
	{ dir: '+z', type: 'I', x: 30, z: 7 },
	{ dir: '+z', type: 'I', x: 30, z: 8 },
	{ dir: '+z', type: 'I', x: 30, z: 9 },
	{ dir: '+z', type: 'I', x: 30, z: 10 },
	{ dir: '+z', type: 'T', x: 30, z: 11 },
	{ dir: '+z', type: 'I', x: 30, z: 12 },
	{ dir: '+z', type: 'I', x: 30, z: 13 },
	{ dir: '+z', type: 'I', x: 30, z: 14 },
	{ dir: '+z', type: 'I', x: 30, z: 15 },
	{ dir: '+z', type: 'I', x: 30, z: 16 },
	{ dir: '+z', type: 'I', x: 30, z: 17 },
	{ dir: '+z', type: 'I', x: 30, z: 18 },
	{ dir: '+z', type: 'I', x: 30, z: 19 },
	{ dir: '+z', type: 'I', x: 30, z: 20 },
	{ dir: '+z', type: 'I', x: 30, z: 21 },
	// bottom (+z)
	{ dir: '-x', type: 'L', x: 0, z: 22 },
	{ dir: '+x', type: 'I', x: 1, z: 22 },
	{ dir: '+x', type: 'I', x: 2, z: 22 },
	{ dir: '+x', type: 'I', x: 3, z: 22 },
	{ dir: '+x', type: 'I', x: 4, z: 22 },
	{ dir: '+x', type: 'I', x: 5, z: 22 },
	{ dir: '+x', type: 'I', x: 6, z: 22 },
	{ dir: '+x', type: 'I', x: 7, z: 22 },
	{ dir: '+x', type: 'I', x: 8, z: 22 },
	{ dir: '+x', type: 'I', x: 9, z: 22 },
	{ dir: '+x', type: 'I', x: 10, z: 22 },
	{ dir: '+x', type: 'I', x: 11, z: 22 },
	{ dir: '+x', type: 'I', x: 12, z: 22 },
	{ dir: '+x', type: 'I', x: 13, z: 22 },
	{ dir: '+x', type: 'I', x: 14, z: 22 },
	{ dir: '+x', type: 'T', x: 15, z: 22, flip: true },
	{ dir: '+x', type: 'I', x: 16, z: 22 },
	{ dir: '+x', type: 'I', x: 17, z: 22 },
	{ dir: '+x', type: 'I', x: 18, z: 22 },
	{ dir: '+x', type: 'I', x: 19, z: 22 },
	{ dir: '+x', type: 'I', x: 20, z: 22 },
	{ dir: '+x', type: 'I', x: 21, z: 22 },
	{ dir: '+x', type: 'I', x: 22, z: 22 },
	{ dir: '+x', type: 'I', x: 23, z: 22 },
	{ dir: '+x', type: 'I', x: 24, z: 22 },
	{ dir: '+x', type: 'I', x: 25, z: 22 },
	{ dir: '+x', type: 'I', x: 26, z: 22 },
	{ dir: '+x', type: 'I', x: 27, z: 22 },
	{ dir: '+x', type: 'I', x: 28, z: 22 },
	{ dir: '+x', type: 'I', x: 29, z: 22 },
	{ dir: '+z', type: 'L', x: 30, z: 22 },
	// left (-x)
	{ dir: '+z', type: 'I', x: 0, z: 1 },
	{ dir: '+z', type: 'I', x: 0, z: 2 },
	{ dir: '+z', type: 'I', x: 0, z: 3 },
	{ dir: '+z', type: 'I', x: 0, z: 4 },
	{ dir: '+z', type: 'I', x: 0, z: 5 },
	{ dir: '+z', type: 'I', x: 0, z: 6 },
	{ dir: '+z', type: 'I', x: 0, z: 7 },
	{ dir: '+z', type: 'I', x: 0, z: 8 },
	{ dir: '+z', type: 'I', x: 0, z: 9 },
	{ dir: '+z', type: 'I', x: 0, z: 10 },
	{ dir: '+z', type: 'T', x: 0, z: 11, flip: true },
	{ dir: '+z', type: 'I', x: 0, z: 12 },
	{ dir: '+z', type: 'I', x: 0, z: 13 },
	{ dir: '+z', type: 'I', x: 0, z: 14 },
	{ dir: '+z', type: 'I', x: 0, z: 15 },
	{ dir: '+z', type: 'I', x: 0, z: 16 },
	{ dir: '+z', type: 'I', x: 0, z: 17 },
	{ dir: '+z', type: 'I', x: 0, z: 18 },
	{ dir: '+z', type: 'I', x: 0, z: 19 },
	{ dir: '+z', type: 'I', x: 0, z: 20 },
	{ dir: '+z', type: 'I', x: 0, z: 21 },

	// Inner Circle
	// top (-z)
	{ dir: '-z', type: 'L', x: 10, z: 6 },
	{ dir: '+x', type: 'I', x: 11, z: 6 },
	{ dir: '+x', type: 'I', x: 12, z: 6 },
	{ dir: '+x', type: 'I', x: 13, z: 6 },
	{ dir: '+x', type: 'I', x: 14, z: 6 },
	{ dir: '+x', type: 'T', x: 15, z: 6, flip: true },
	{ dir: '+x', type: 'I', x: 16, z: 6 },
	{ dir: '+x', type: 'I', x: 17, z: 6 },
	{ dir: '+x', type: 'I', x: 18, z: 6 },
	{ dir: '+x', type: 'I', x: 19, z: 6 },
	{ dir: '+x', type: 'L', x: 20, z: 6 },
	// right (+x)
	{ dir: '+z', type: 'I', x: 20, z: 7 },
	{ dir: '+z', type: 'I', x: 20, z: 8 },
	{ dir: '+z', type: 'I', x: 20, z: 9 },
	{ dir: '+z', type: 'I', x: 20, z: 10 },
	{ dir: '+z', type: 'T', x: 20, z: 11, flip: true },
	{ dir: '+z', type: 'I', x: 20, z: 12 },
	{ dir: '+z', type: 'I', x: 20, z: 13 },
	{ dir: '+z', type: 'I', x: 20, z: 14 },
	{ dir: '+z', type: 'I', x: 20, z: 15 },
	// bottom (+z)
	{ dir: '-x', type: 'L', x: 10, z: 16 },
	{ dir: '+x', type: 'I', x: 11, z: 16 },
	{ dir: '+x', type: 'I', x: 12, z: 16 },
	{ dir: '+x', type: 'I', x: 13, z: 16 },
	{ dir: '+x', type: 'I', x: 14, z: 16 },
	{ dir: '+x', type: 'T', x: 15, z: 16 },
	{ dir: '+x', type: 'I', x: 16, z: 16 },
	{ dir: '+x', type: 'I', x: 17, z: 16 },
	{ dir: '+x', type: 'I', x: 18, z: 16 },
	{ dir: '+x', type: 'I', x: 19, z: 16 },
	{ dir: '+z', type: 'L', x: 20, z: 16 },
	// left (-x)
	{ dir: '+z', type: 'I', x: 10, z: 7 },
	{ dir: '+z', type: 'I', x: 10, z: 8 },
	{ dir: '+z', type: 'I', x: 10, z: 9 },
	{ dir: '+z', type: 'I', x: 10, z: 10 },
	{ dir: '+z', type: 'T', x: 10, z: 11 },
	{ dir: '+z', type: 'I', x: 10, z: 12 },
	{ dir: '+z', type: 'I', x: 10, z: 13 },
	{ dir: '+z', type: 'I', x: 10, z: 14 },
	{ dir: '+z', type: 'I', x: 10, z: 15 },

	// LINK ROADS
	// top
	{ dir: '+z', type: 'I', x: 15, z: 1 },
	{ dir: '+z', type: 'I', x: 15, z: 2 },
	{ dir: '+z', type: 'I', x: 15, z: 3 },
	{ dir: '+z', type: 'I', x: 15, z: 4 },
	{ dir: '+z', type: 'I', x: 15, z: 5 },
	// right
	{ dir: '+x', type: 'I', x: 21, z: 11 },
	{ dir: '+x', type: 'I', x: 22, z: 11 },
	{ dir: '+x', type: 'I', x: 23, z: 11 },
	{ dir: '+x', type: 'I', x: 24, z: 11 },
	{ dir: '+x', type: 'I', x: 25, z: 11 },
	{ dir: '+x', type: 'I', x: 26, z: 11 },
	{ dir: '+x', type: 'I', x: 27, z: 11 },
	{ dir: '+x', type: 'I', x: 28, z: 11 },
	{ dir: '+x', type: 'I', x: 29, z: 11 },
	// bottom
	{ dir: '+z', type: 'I', x: 15, z: 17 },
	{ dir: '+z', type: 'I', x: 15, z: 18 },
	{ dir: '+z', type: 'I', x: 15, z: 19 },
	{ dir: '+z', type: 'I', x: 15, z: 20 },
	{ dir: '+z', type: 'I', x: 15, z: 21 },
	// left
	{ dir: '+x', type: 'I', x: 1, z: 11 },
	{ dir: '+x', type: 'I', x: 2, z: 11 },
	{ dir: '+x', type: 'I', x: 3, z: 11 },
	{ dir: '+x', type: 'I', x: 4, z: 11 },
	{ dir: '+x', type: 'I', x: 5, z: 11 },
	{ dir: '+x', type: 'I', x: 6, z: 11 },
	{ dir: '+x', type: 'I', x: 7, z: 11 },
	{ dir: '+x', type: 'I', x: 8, z: 11 },
	{ dir: '+x', type: 'I', x: 9, z: 11 },
];
