import {
	AdditiveBlending,
	BufferAttribute,
	BufferGeometry,
	Color,
	Mesh,
	MeshPhongMaterial,
	PointLight,
	Points,
	ShaderMaterial,
	SphereGeometry,
	TextureLoader,
	Vector3,
} from 'three';
import { World } from './World';

const textureURL = 'textures/moon-texture.jpg';
// const displacementURL = 'textures/moon-displacement.jpg';

export class Moons {
	static init() {
		// Geometry
		const geometry = new SphereGeometry(200, 64, 64);

		// Material
		const textureLoader = new TextureLoader();
		const texture = textureLoader.load(textureURL);
		// const displacementMap = textureLoader.load(displacementURL);

		const material = new MeshPhongMaterial({
			color: 0xaaaaff,
			// emmisive: 0x4444aa,
			map: texture,
			// displacementMap: displacementMap,
			// displacementScale: 2,
			// bumpMap: displacementMap,
			// bumpScale: 1,
			reflectivity: 0,
			shininess: 0,
		});

		const moon = new Mesh(geometry, material);
		moon.position.set(650, 50, 160);

		World.scene.add(moon);

		// ======== LIGHT ON MOON ==================
		const light = new PointLight(0xfec1f4, 4, 550, 0.1);
		light.position.set(600, 85, 670);
		World.scene.add(light);
		// ======== LIGHT ON MOON END ==================

		Moons.addStars();
	}

	static addStars() {
		const amount = 10000;

		const positions = new Float32Array(amount * 3);
		const colors = new Float32Array(amount * 3);
		const sizes = new Float32Array(amount);

		const vertex = new Vector3();
		const color = new Color(0xffffff);

		const r = 700;
		let lon, lat;

		for (let i = 0; i < amount; i++) {
			lat = Math.random() * 2 * Math.PI;
			lon = Math.random() * Math.PI;

			vertex.x = r * Math.sin(-lon) * -Math.cos(lat) + 150;
			vertex.y = r * Math.sin(lat);
			vertex.z = r * Math.cos(lat) * Math.cos(-lon) + 100;

			vertex.toArray(positions, i * 3);

			if (vertex.x < 0) {
				// color.setHSL(0.5 + 0.1 * (i / amount), 0.7, 0.5);
			} else {
				// color.setHSL(0.0 + 0.1 * (i / amount), 0.9, 0.5);
			}

			color.toArray(colors, i * 3);

			sizes[i] = 10;
		}

		const geometry = new BufferGeometry();
		geometry.setAttribute('position', new BufferAttribute(positions, 3));
		geometry.setAttribute('customColor', new BufferAttribute(colors, 3));
		geometry.setAttribute('size', new BufferAttribute(sizes, 1));

		//

		const material = new ShaderMaterial({
			uniforms: {
				color: { value: new Color(0xffffff) },
				pointTexture: {
					value: new TextureLoader().load('textures/spark1.png'),
				},
			},
			vertexShader: document.getElementById('vertexshader').textContent,
			fragmentShader:
				document.getElementById('fragmentshader').textContent,

			blending: AdditiveBlending,
			// depthTest: false,
			transparent: true,
		});

		//
		const sphere = new Points(geometry, material);
		World.scene.add(sphere);
	}
}
