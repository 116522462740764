import {
	BufferAttribute,
	BufferGeometry,
	ConeGeometry,
	ExtrudeGeometry,
	Mesh,
	MeshPhongMaterial,
	Quaternion,
	Shape,
	SphereGeometry,
	Vector3,
} from 'three';
import { Shapes } from '../geometries/Shapes';
import { World } from './World';

const pos = new Vector3();
const quat = new Quaternion();

const green = new MeshPhongMaterial({ color: 0x55aa55, shininess: 0 });
const brown = new MeshPhongMaterial({
	color: 0x71392a,
	shininess: 0,
});

const groundOutline = [
	{ x: 0, y: 0, z: -9 },
	{ x: -37, y: 0, z: 26 },
	{ x: -35, y: 0, z: 90 },
	{ x: -27, y: 0, z: 150 },
	{ x: -2, y: 0, z: 188 },
	{ x: 40, y: 0, z: 206 },
	{ x: 121, y: 0, z: 217 },
	{ x: 209, y: 0, z: 226 },
	{ x: 242, y: 0, z: 209 },
	{ x: 274, y: 0, z: 153 },
	{ x: 289, y: 0, z: 89 },
	{ x: 276, y: 0, z: 25 },
	{ x: 241, y: 0, z: -10 },
	{ x: 209, y: 0, z: -19 },
	{ x: 165, y: 0, z: -28 },
	{ x: 120, y: 0, z: -31 },
	{ x: 57, y: 0, z: -23 },
];

const r = 170;
const hGrass = 2;

export class Ground {
	static init() {
		pos.set(120, -hGrass / 2, 90);

		Ground.grass();
		Ground.earth();
		Ground.downwardConeRocks();
		// Ground.outline();
	}

	static grass() {
		const shape = new Shape();

		shape.moveTo(groundOutline[0].x, groundOutline[0].z);

		for (let i = 1; i < groundOutline.length; i++) {
			shape.lineTo(groundOutline[i].x, groundOutline[i].z);
		}

		const extrudeSettings = {
			steps: 2,
			depth: hGrass,
			bevelEnabled: true,
			bevelThickness: 0,
			bevelSize: 0,
			bevelOffset: 0,
			bevelSegments: 0,
		};

		const geometry = new ExtrudeGeometry(shape, extrudeSettings);
		geometry.rotateX(Math.PI / 2);
		const grass = Shapes.convexHull(null, quat, geometry, green, 0);
		grass.receiveShadow = true;
	}

	static earth() {
		const geometry = new BufferGeometry();

		let pts = [];
		const positions = [...groundOutline];
		positions.push(groundOutline[0]);
		for (let i = 0; i < groundOutline.length; i++) {
			const pt1 = positions[i + 1];
			const pt2 = positions[i];
			const pt3 = { x: 120, y: -100, z: 88 };

			pts.push(pt1.x, pt1.y - 0.5 * hGrass, pt1.z);
			pts.push(pt2.x, pt2.y - 0.5 * hGrass, pt2.z);
			pts.push(pt3.x, pt3.y - 0.5 * hGrass, pt3.z);
		}

		const vertices = new Float32Array(pts);
		geometry.setAttribute('position', new BufferAttribute(vertices, 3));
		geometry.computeVertexNormals();
		const mesh = new Mesh(geometry, brown);

		World.scene.add(mesh);
	}

	static downwardConeRocks() {
		// t is from azimuth, ~ 0.75~1
		// s is from back, between 0 and 1

		const coords = [
			{ s: 0.0, t: 0.9, radius: r / 8, h: 40, segs: 3, up: 90 },
			{ s: 0.35, t: 0.9, radius: r / 8, h: 40, segs: 3, up: 100 },
			{ s: 0.65, t: 0.9, radius: r / 8, h: 50, segs: 3, up: 110 },
			{ s: 0.85, t: 0.9, radius: r / 8, h: 50, segs: 3, up: 90 },
			{ s: 0.5, t: 0.82, radius: r / 8, h: 40, segs: 3, up: 100 },
			{ s: 0.62, t: 0.8, radius: r / 8, h: 40, segs: 3, up: 100 },
			{ s: 0.1, t: 0.8, radius: r / 5, h: 40, segs: 3, up: 101 },
			{ s: 0.25, t: 0.85, radius: r / 5, h: 40, segs: 3, up: 117 },
			{ s: 0.45, t: 0.82, radius: r / 3, h: 45, segs: 3, up: 112 },
			{ s: 0.45, t: 0.95, radius: r / 3, h: 45, segs: 3, up: 90 },
			{ s: 0.9, t: 0.8, radius: r / 3, h: 45, segs: 3, up: 110 },
			{ s: 0.55, t: 0.8, radius: r / 3, h: 45, segs: 3, up: 110 },
			{ s: 0.55, t: 1, radius: r / 3, h: 65, segs: 3, up: 90 },
			{ s: 0, t: 0.75, radius: r / 3, h: 65, segs: 3, up: 90 },
		];

		coords.forEach((coord, i) => {
			const { radius, h, segs, up } = coord;
			const s = 2 * Math.PI * coord.s;
			const t = Math.PI * coord.t;
			const x = r * Math.cos(s) * Math.sin(t);
			const y = r * Math.cos(t);
			const z = r * Math.sin(s) * Math.sin(t);

			const geometry = new ConeGeometry(radius, h, segs, 1, false);
			const rockMesh = new Mesh(geometry, brown);
			// i === coords.length - 1
			// 	? new MeshPhongMaterial({ color: 0x91ff2a, shininess: 0 })
			// 	: brown
			rockMesh.position.set(x, y, z).add(pos);
			rockMesh.translateY(-hGrass + up);
			rockMesh.rotateX(Math.PI);
			World.scene.add(rockMesh);
		});
	}

	static outline() {
		const yellow = new MeshPhongMaterial({ color: 0xffff00, shininess: 0 });
		groundOutline.forEach((coord, i) => {
			const m = new Mesh(new SphereGeometry(1, 16, 16), yellow);
			m.position.set(coord.x, hGrass, coord.z);
			World.scene.add(m);
			const folder = World.dat.addFolder(i);
			folder
				.add(m.position, 'x', -50, 300)
				.listen()
				.onChange(() => {
					console.log(groundOutline);
				});
			folder
				.add(m.position, 'z', -90, 250)
				.listen()
				.onChange(() => {
					console.log(groundOutline);
				});
		});
	}

	static testStuff() {
		// pos.set(0, 0, 0);
		// const mat = new MeshStandardMaterial({ color: 0xffaa66 });
		// const mat2 = new MeshStandardMaterial({ color: 0x34aa00 });
		// const mat3 = new MeshStandardMaterial({ color: 0x34aaff });
		// Fall test
		// pos.set(128, 100, 88);
		// Shapes.box(2, 1, 2, pos, null, mat, 10);
		// pos.set(0, 0.5, 0);
		// Shapes.box(1, 1, 1, pos, null, mat, 10);
		// pos.set(0, 3, 0);
		// Shapes.box(1, 1, 1, pos, null, mat2, 10);
		// pos.set(0, 3, 0);
		// Shapes.cylinder(1, 1, pos, null, mat, 10);
		// pos.set(0, 4, 0);
		// Shapes.pyramid(4, 3, pos, null, mat, 10);
		// pos.set(-2, 7, 0);
		// Shapes.cone(2, 3, pos, null, mat3, 10);
		// pos.set(5, 2, 5);
		// const mesh = Shapes.ball(2, pos, null, mat3, 10, 1);
		// mesh.userData.body.setLinearVelocity(new AP.Ammo.btVector3(50, 0, 30));
	}
}
