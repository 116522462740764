import {
	Vector3,
	Group,
	MeshPhongMaterial,
	Quaternion,
	SpotLight,
	Object3D,
	Mesh,
	PlaneGeometry,
	MeshBasicMaterial,
	DoubleSide,
} from 'three';
import { World } from '../scene/World';
import { Shapes } from '../geometries/Shapes';
import { AP } from '../scene/AmmoPhysics';

const pos = new Vector3();
const quat = new Quaternion(0.5, 0, 0, 0.5).normalize();

const mat = new MeshPhongMaterial({ color: 0xaaaaaa });
const lightPatchMat = new MeshBasicMaterial({
	color: 0xffffaa,
	side: DoubleSide,
});

export class StreetLights {
	static init() {
		const positions = [
			{ x: 10, y: 0, z: -5, rot: 0 },
			{ x: -5, y: 0, z: 88, rot: Math.PI / 2 },
			{ x: 10, y: 0, z: 181, rot: Math.PI },

			{ x: 120, y: 0, z: -5, rot: 0 },
			{ x: 120, y: 0, z: 181, rot: Math.PI },

			{ x: 230, y: 0, z: -5, rot: 0 },
			{ x: 245, y: 0, z: 88, rot: -Math.PI / 2 },
			{ x: 230, y: 0, z: 181, rot: Math.PI },

			{ x: 75, y: 0, z: 43, rot: Math.PI / 4 },
			{ x: 75, y: 0, z: 133, rot: -Math.PI / (3 / 4) },

			{ x: 165, y: 0, z: 43, rot: -Math.PI / 4 },
			{ x: 165, y: 0, z: 133, rot: Math.PI / (3 / 4) },
		];

		positions.forEach(StreetLights.create);
	}

	static create(data) {
		const group = new Group();

		const hPost = 10;

		// Post
		const postPos = new Vector3(0, hPost / 2, 0);
		const post = Shapes.cylinder(0.2, hPost, postPos, null, mat, 0);
		post.name = 'light-post';

		// Beam
		const beamPos = new Vector3(0, hPost, hPost / 5);
		const beam = Shapes.cylinder(0.2, hPost / 2, beamPos, quat, mat, 0);
		beam.name = 'light-beam';

		// Cowl
		const cowlPos = new Vector3(0, hPost, hPost / 2.5);
		const cowl = Shapes.box(0.6, 0.6, 1, cowlPos, null, mat, 0);
		cowl.name = 'light-cowl';

		// Spotlight target
		const targetPos = cowlPos.clone().add(new Vector3(0, -5, 1.3));
		const target = new Object3D();
		target.position.copy(targetPos);
		target.name = 'streetlight-spotlight-target';
		target.updateMatrixWorld();

		// Spotlight
		const sl = new SpotLight(0xffffaa, 1, 50, Math.PI / 3, 0.1, 0.3);
		sl.position.copy(cowl.position.clone().sub(new Vector3(0, 0.31, 0)));
		sl.target = target;
		// sl.castShadow = true;
		sl.shadow.bias = -0.0005;
		// sl.shadow.normalBias = 0.06;

		// White patch on cowl
		const lightPatchGeo = new PlaneGeometry(0.5, 1);
		const lightPatch = new Mesh(lightPatchGeo, lightPatchMat);

		lightPatch.translateY(-0.31);
		lightPatch.rotateX(Math.PI / 2);
		cowl.add(lightPatch);

		// Remove physics mesh items from the world
		World.scene.remove(post, beam, cowl);
		group.add(post, beam, cowl, sl, target);

		// Add them to group, rotate and position, then add group
		World.scene.add(group);
		pos.copy(data); //.add(new Vector3(0, hPost / 2, 0));
		group.position.copy(pos);
		group.rotateY(data.rot);

		// Position physics bodies
		const tf = new AP.Ammo.btTransform();
		const ps = new Vector3();
		const qt = new Quaternion();

		group.traverse((child) => {
			const { body } = child.userData;

			if (!body) return;

			tf.setIdentity();

			// Get mesh transforms
			child.getWorldPosition(ps);
			child.getWorldQuaternion(qt);

			// Create ammo transforms
			tf.setOrigin(new AP.Ammo.btVector3(ps.x, ps.y, ps.z));
			tf.setRotation(new AP.Ammo.btQuaternion(qt.x, qt.y, qt.z, qt.w));

			body.setWorldTransform(tf);
		});
	}
}
