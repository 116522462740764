import 'three/examples/js/libs/ammo.wasm.js';
import { World } from './World';

let tempBtVec3_1; // For convex hull calculations
let lastTime = 0;

export class AP {
	static Ammo = null;
	static world;
	static gravity = -9.82;
	static rigidBodies = [];
	static worldTransform;
	static shapeMargin = 0.05;
	static friction = 0.8;
	static subSteps = 10;

	static init() {
		return window.Ammo().then((AmmoLib) => {
			AP.Ammo = AmmoLib;

			// Init helpers
			tempBtVec3_1 = new AP.Ammo.btVector3();

			// Create Physics World
			AP.initPhysics();
		});
	}

	static initPhysics() {
		// Physics configuration
		const { Ammo, gravity } = AP;

		const collisionConfig = new Ammo.btDefaultCollisionConfiguration();
		const dispatcher = new Ammo.btCollisionDispatcher(collisionConfig);
		const broadphase = new Ammo.btDbvtBroadphase();
		const solver = new Ammo.btSequentialImpulseConstraintSolver();
		AP.world = new Ammo.btDiscreteDynamicsWorld(
			dispatcher,
			broadphase,
			solver,
			collisionConfig
		);
		AP.world.setGravity(new Ammo.btVector3(0, gravity, 0));

		// Soft
		// const collisionConfig =
		// 	new Ammo.btSoftBodyRigidBodyCollisionConfiguration();
		// const dispatcher = new Ammo.btCollisionDispatcher(collisionConfig);
		// const broadphase = new Ammo.btDbvtBroadphase();
		// const solver = new Ammo.btSequentialImpulseConstraintSolver();
		// const softBodySolver = new Ammo.btDefaultSoftBodySolver();
		// AP.world = new Ammo.btSoftRigidDynamicsWorld(
		// 	dispatcher,
		// 	broadphase,
		// 	solver,
		// 	collisionConfig,
		// 	softBodySolver
		// );
		// AP.world.setGravity(new Ammo.btVector3(0, gravity, 0));
		// AP.world
		// 	.getWorldInfo()
		// 	.set_m_gravity(new Ammo.btVector3(0, gravity, 0));

		// Initialise helpers
		AP.worldTransform = new Ammo.btTransform();

		setInterval(AP.animate, 1000 / 60);
	}

	static remove(mesh) {
		World.scene.remove(mesh);
		AP.world.removeRigidBody(mesh.userData.body);
	}

	static getShape(geometry) {
		const { Ammo, shapeMargin } = AP;

		if (geometry.type === 'BoxGeometry') {
			const params = geometry.parameters;
			const { width, height, depth } = params;

			const sx = width !== undefined ? width / 2 : 0.5;
			const sy = height !== undefined ? height / 2 : 0.5;
			const sz = depth !== undefined ? depth / 2 : 0.5;

			const dims = new Ammo.btVector3(sx, sy, sz);
			const shape = new Ammo.btBoxShape(dims);
			shape.setMargin(shapeMargin);

			return shape;
		} else if (
			['SphereGeometry', 'IcosahedronGeometry'].includes(geometry.type)
		) {
			const params = geometry.parameters;
			const { radius } = params;

			const r = radius !== undefined ? radius : 1;

			const sphereShape = new AP.Ammo.btSphereShape(r);
			sphereShape.setMargin(AP.shapeMargin);

			return sphereShape;
		} else if (geometry.type === 'CylinderGeometry') {
			const params = geometry.parameters;
			const { radiusTop, height } = params;

			const r = radiusTop !== undefined ? radiusTop : 1;

			const dims = new Ammo.btVector3(r, height / 2, r);
			const cylinderShape = new AP.Ammo.btCylinderShape(dims);
			cylinderShape.setMargin(AP.shapeMargin);

			return cylinderShape;
		} else if (geometry.type === 'ConeGeometry') {
			const params = geometry.parameters;
			const { radius, height } = params;

			const r = radius !== undefined ? radius : 1;

			const dims = new Ammo.btVector3(r, height / 2, r);
			const coneShape = new AP.Ammo.btCylinderShape(dims);
			coneShape.setMargin(AP.shapeMargin);

			return coneShape;
		} else {
			const coords = geometry.attributes.position.array;
			const otherShape = AP.convexHullPhysicsShape(coords);

			if (coords.length > 40) {
				console.warn(
					'Using a btConvexHullShape with > 40 points. This can cause severe lag.' +
						coords.length
				);
			}

			otherShape.setMargin(AP.shapeMargin);
			return otherShape;
		}
	}

	static convexHullPhysicsShape(coords) {
		const { Ammo } = AP;

		const shape = new Ammo.btConvexHullShape();

		for (let i = 0, il = coords.length; i < il; i += 3) {
			tempBtVec3_1.setValue(coords[i], coords[i + 1], coords[i + 2]);
			const lastOne = i >= il - 3;
			shape.addPoint(tempBtVec3_1, lastOne);
		}

		return shape;
	}

	static addMesh(mesh, mass = 0, friction = AP.friction) {
		const shape = AP.getShape(mesh.geometry);

		if (shape !== null) {
			if (mesh.isInstancedMesh) {
				AP.handleInstancedMesh(mesh, mass, shape, friction);
			} else if (mesh.isMesh) {
				AP.handleMesh(mesh, mass, shape, friction);
			}
		}
	}

	static handleMesh(mesh, mass, shape, friction) {
		const { Ammo, world, rigidBodies, gravity } = AP;
		const { position: pos, quaternion: qt } = mesh;

		const transform = new Ammo.btTransform();
		transform.setIdentity();
		transform.setOrigin(new Ammo.btVector3(pos.x, pos.y, pos.z));
		transform.setRotation(new Ammo.btQuaternion(qt.x, qt.y, qt.z, qt.w));

		const motionState = new Ammo.btDefaultMotionState(transform);

		const localInertia = new Ammo.btVector3(0, 0, 0);
		shape.calculateLocalInertia(mass, localInertia);

		const rbInfo = new Ammo.btRigidBodyConstructionInfo(
			mass,
			motionState,
			shape,
			localInertia
		);

		const body = new Ammo.btRigidBody(rbInfo);
		body.setFriction(friction);
		body.setRollingFriction(1);
		body.setRestitution(0.4);
		body.setGravity(new Ammo.btVector3(0, gravity, 0));

		mesh.userData.body = body;
		mesh.userData.collided = false;

		if (mass > 0) {
			rigidBodies.push(mesh);
			body.setActivationState(4);
		}

		world.addRigidBody(body);
	}

	static handleInstancedMesh(mesh, mass, shape, friction) {
		const { array } = mesh.instanceMatrix;

		const bodies = [];

		for (let i = 0; i < mesh.count; i++) {
			const index = i * 16;

			const transform = new AP.Ammo.btTransform();
			transform.setFromOpenGLMatrix(array.slice(index, index + 16));

			const motionState = new AP.Ammo.btDefaultMotionState(transform);

			const localInertia = new AP.Ammo.btVector3(0, 0, 0);
			shape.calculateLocalInertia(mass, localInertia);

			const rbInfo = new AP.Ammo.btRigidBodyConstructionInfo(
				mass,
				motionState,
				shape,
				localInertia
			);

			const body = new AP.Ammo.btRigidBody(rbInfo);
			body.setFriction(friction);
			// body.setRollingFriction(0.5);

			AP.world.addRigidBody(body);

			bodies.push(body);
		}

		if (mass > 0) AP.rigidBodies.push(mesh);
	}

	static setMeshPosition(mesh, position, index = 0) {
		if (mesh.isInstancedMesh) {
			const bodies = mesh.userData.body;
			const body = bodies[index];

			body.setAngularVelocity(new AP.Ammo.btVector3(0, 0, 0));
			body.setLinearVelocity(new AP.Ammo.btVector3(0, 0, 0));

			AP.worldTransform.setIdentity();
			AP.worldTransform.setOrigin(
				new AP.Ammo.btVector3(position.x, position.y, position.z)
			);
			body.setWorldTransform(AP.worldTransform);
		} else if (mesh.isMesh) {
			const body = mesh.userData.body;

			body.setAngularVelocity(new AP.Ammo.btVector3(0, 0, 0));
			body.setLinearVelocity(new AP.Ammo.btVector3(0, 0, 0));

			AP.worldTransform.setIdentity();
			AP.worldTransform.setOrigin(
				new AP.Ammo.btVector3(position.x, position.y, position.z)
			);
			body.setWorldTransform(AP.worldTransform);
		}
	}

	static animate(d) {
		const { world, rigidBodies, worldTransform, subSteps } = AP;

		// console.log(delta);
		// world.stepSimulation(d, 2, 1 / 30);

		const time = performance.now();
		if (lastTime > 0) {
			const delta = (time - lastTime) / 1000; // console.time( 'world.step' );
			world.stepSimulation(delta, subSteps); // console.timeEnd( 'world.step' );
		}
		lastTime = time;

		for (let i = 0, l = rigidBodies.length; i < l; i++) {
			const mesh = rigidBodies[i];

			if (!mesh) {
				rigidBodies.splice(i, 1);
				continue;
			}

			if (mesh.isInstancedMesh) {
				const array = mesh.instanceMatrix.array;
				const bodies = mesh.userData.body;

				for (let j = 0; j < bodies.length; j++) {
					const body = bodies[j];

					const motionState = body.getMotionState();
					motionState.getWorldTransform(worldTransform);

					const pos = worldTransform.getOrigin();
					const quat = worldTransform.getRotation();

					compose(pos, quat, array, j * 16);
				}

				mesh.instanceMatrix.needsUpdate = true;
			} else if (mesh.isMesh) {
				const { body, velocity: initVelocity } = mesh.userData;

				const motionState = body.getMotionState();
				motionState.getWorldTransform(worldTransform);

				const pos = worldTransform.getOrigin();
				const quat = worldTransform.getRotation();
				mesh.position.set(pos.x(), pos.y(), pos.z());
				mesh.quaternion.set(quat.x(), quat.y(), quat.z(), quat.w());

				if (mesh.name === 'bullet') {
					const linVel = body.getLinearVelocity();
					const x = linVel.x();
					const z = linVel.z();
					const currentVel = Math.sqrt(x * x + z * z);

					if (currentVel < 0.99 * initVelocity) {
						AP.remove(mesh);
						rigidBodies.splice(i, 1);
					}
				}
			}
		}
	}
}

/** Step an instanced mesh */
function compose(position, quaternion, array, index) {
	const x = quaternion.x(),
		y = quaternion.y(),
		z = quaternion.z(),
		w = quaternion.w();
	const x2 = x + x,
		y2 = y + y,
		z2 = z + z;
	const xx = x * x2,
		xy = x * y2,
		xz = x * z2;
	const yy = y * y2,
		yz = y * z2,
		zz = z * z2;
	const wx = w * x2,
		wy = w * y2,
		wz = w * z2;

	array[index + 0] = 1 - (yy + zz);
	array[index + 1] = xy + wz;
	array[index + 2] = xz - wy;
	array[index + 3] = 0;

	array[index + 4] = xy - wz;
	array[index + 5] = 1 - (xx + zz);
	array[index + 6] = yz + wx;
	array[index + 7] = 0;

	array[index + 8] = xz + wy;
	array[index + 9] = yz - wx;
	array[index + 10] = 1 - (xx + yy);
	array[index + 11] = 0;

	array[index + 12] = position.x();
	array[index + 13] = position.y();
	array[index + 14] = position.z();
	array[index + 15] = 1;
}
