import {
	BoxGeometry,
	ConeGeometry,
	CylinderGeometry,
	Mesh,
	Quaternion,
	SphereGeometry,
	Vector3,
} from 'three';
import { AP } from '../scene/AmmoPhysics';
import { World } from '../scene/World';

const position = new Vector3(0, 0, 0);
const quaternion = new Quaternion(0, 0, 0, 1);

const defaultPosition = new Vector3(0, 0, 0);
const defaultQuaternion = new Quaternion(0, 0, 0, 1);

export class Shapes {
	static add(geo, mat, mass, friction, physics) {
		const mesh = new Mesh(geo, mat);

		mesh.receiveShadow = true;
		mesh.castShadow = true;

		mesh.position.copy(position);
		mesh.quaternion.copy(quaternion);

		World.scene.add(mesh);

		if (mass !== undefined) AP.addMesh(mesh, mass, friction);

		return mesh;
	}

	static text(w, h, d, geo, pos, quat, mat, mass, friction) {
		geo.translate(-w / 2, -h / 2, -d / 2);

		pos ? position.copy(pos) : position.copy(defaultPosition);
		quat ? quaternion.copy(quat) : quaternion.copy(defaultQuaternion);

		// Use convex hull
		// const mesh = Shapes.add(geo, mat, mass, friction);

		// Use box for body - waaaay less lag
		const box = new BoxGeometry(w, h, d, 1, 1, 1);
		const mesh = Shapes.add(box, mat, mass, friction);
		mesh.geometry = geo;

		return mesh;
	}

	static box(w, h, d, pos, quat, mat, mass, friction) {
		const geometry = new BoxGeometry(w, h, d, 1, 1, 1);

		pos ? position.copy(pos) : position.copy(defaultPosition);
		quat ? quaternion.copy(quat) : quaternion.copy(defaultQuaternion);

		return Shapes.add(geometry, mat, mass, friction);
	}

	static cylinder(r, h, pos, quat, mat, mass, friction) {
		const geometry = new CylinderGeometry(r, r, h, 32);

		pos ? position.copy(pos) : position.copy(defaultPosition);
		quat ? quaternion.copy(quat) : quaternion.copy(defaultQuaternion);

		return Shapes.add(geometry, mat, mass, friction);
	}

	static pyramid4(w, h, pos, quat, mat, mass, friction) {
		position.set(pos.x, pos.y, pos.z);
		quat ? quaternion.copy(quat) : quaternion.copy(defaultQuaternion);

		const params = [w / 2, h, 4, 1, false, Math.PI / 4, 2 * Math.PI];
		const geometry = new ConeGeometry(...params);

		return Shapes.add(geometry, mat, mass, friction);
	}

	static cone(r, h, pos, quat, mat, mass, friction) {
		position.set(pos.x, pos.y, pos.z);
		quat ? quaternion.copy(quat) : quaternion.copy(defaultQuaternion);

		const params = [r, h, 32, 1, false, Math.PI / 4, 2 * Math.PI];
		const geometry = new ConeGeometry(...params);

		return Shapes.add(geometry, mat, mass, friction);
	}

	static pyramid(r, h, segs, pos, quat, mat, mass, friction) {
		position.set(pos.x, pos.y, pos.z);
		quat ? quaternion.copy(quat) : quaternion.copy(defaultQuaternion);

		const params = [r, h, segs, 1, false, Math.PI / 4, 2 * Math.PI];
		const geometry = new ConeGeometry(...params);

		return Shapes.add(geometry, mat, mass, friction);
	}

	static sphere(r, pos, quat, mat, mass, friction) {
		pos ? position.copy(pos) : position.copy(defaultPosition);
		quat ? quaternion.copy(quat) : quaternion.copy(defaultQuaternion);

		const geometry = new SphereGeometry(r, 32, 32);

		return Shapes.add(geometry, mat, mass, friction);
	}

	static convexHull(pos, quat, geo, mat, mass, friction) {
		pos ? position.copy(pos) : position.copy(defaultPosition);
		quat ? quaternion.copy(quat) : quaternion.copy(defaultQuaternion);

		return Shapes.add(geo, mat, mass, friction);
	}
}
