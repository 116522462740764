import { CylinderGeometry, DoubleSide, Mesh, MeshBasicMaterial } from 'three';
import gsap from 'gsap';

const animateTo = (mesh, y, duration) => {
	return gsap.to(mesh.position, { y, duration, ease: 'Sine.easeInOut' });
};

const animateFromTo = (mesh, yi, yf, duration, delay = 0) => {
	return gsap.fromTo(
		mesh.position,
		{ y: yi },
		{
			y: yf,
			duration,
			repeat: -1,
			yoyo: true,
			ease: 'Sine.easeInOut',
			delay,
		}
	);
};

const activeTop = -4;
const activeBtm = -5;

const inactiveTop = -6;
const inactiveBtm = -6.2;

export class Portal {
	isActive = false;
	mesh1 = null;
	mesh2 = null;

	constructor(parent, offset) {
		const material = new MeshBasicMaterial({
			color: 0xaaaaff,
			side: DoubleSide,
			transparent: true,
			opacity: 0.4,
		});

		const geometry1 = new CylinderGeometry(4.5, 5, 0, 64, 1, true);
		const cylinder1 = new Mesh(geometry1, material);
		cylinder1.position.copy(offset);
		parent.add(cylinder1);

		const geometry2 = new CylinderGeometry(3.5, 4, 0, 64, 1, true);
		const cylinder2 = new Mesh(geometry2, material);
		cylinder2.position.copy(offset);
		parent.add(cylinder2);

		this.mesh1 = cylinder1;
		this.mesh2 = cylinder2;
		this.inactive();
	}

	active = () => {
		if (!this.isActive) {
			this.isActive = true;
			// Animate mesh 1
			animateTo(this.mesh1, activeTop, 0.6).then(() => {
				animateFromTo(this.mesh1, activeTop, activeBtm, 1);
			});

			// Animate mesh 2
			animateTo(this.mesh2, activeTop, 0.6).then(() => {
				setTimeout(() => {
					animateFromTo(this.mesh2, activeTop, activeBtm, 1);
				}, 200);
			});
		} else {
			this.isActive = true;
			// Animate meshs
			animateFromTo(this.mesh1, activeTop, activeBtm, 1);
			setTimeout(() => {
				animateFromTo(this.mesh2, activeTop, activeBtm, 1);
			}, 200);
		}
	};

	inactive = () => {
		if (this.isActive) {
			// Animate mesh 1
			animateTo(this.mesh1, inactiveBtm, 0.3).then(() => {
				animateFromTo(this.mesh1, inactiveBtm, inactiveTop, 1);
				this.isActive = false;
			});

			// Animate mesh 2
			animateTo(this.mesh2, inactiveBtm, 0.3).then(() => {
				setTimeout(() => {
					animateFromTo(this.mesh2, inactiveBtm, inactiveTop, 1);
				}, 200);
			});
		} else {
			// Animate meshs
			animateFromTo(this.mesh1, inactiveBtm, inactiveTop, 1);
			setTimeout(() => {
				animateFromTo(this.mesh2, inactiveBtm, inactiveTop, 1);
			}, 200);
			this.isActive = false;
		}
	};
}
