import { Vector3, MeshStandardMaterial } from 'three';
import { Shapes } from '../geometries/Shapes';
import { AP } from '../scene/AmmoPhysics';

const pos1 = new Vector3();
const pos2 = new Vector3();
const green = new MeshStandardMaterial({ color: 0x3d810b });
const brown = new MeshStandardMaterial({ color: 0x5d3109 });

const tree1 = {
	trunkHeight: 2,
	trunkDiameter: 0.3,
	foliageWidth: 1,
	foliageHeight: 1,
	position: {},
};

const tree2 = {
	trunkHeight: 1,
	trunkDiameter: 0.5,
	foliageWidth: 1,
	foliageHeight: 2,
	position: {},
};

export class Tree {
	static createMany() {
		const trees = [
			[11, 0, 152.1, tree2],
			[40.9, 0, 156.5, tree2],
			[168.7, 0, 153.5, tree2],
			[228.3, 0, 167.8, tree1],
			[174.7, 0, 137.6, tree2],
			[36.2, 0, 100.4, tree1],
			[127.1, 0, 147.5, tree2],
			[11.1, 0, 69.9, tree2],
			[188.8, 0, 132.8, tree1],
			[121.1, 0, 123.2, tree2],
			[232.1, 0, 110.9, tree1],
			[219, 0, 124.7, tree2],
			[150.6, 0, 60.8, tree2],
			[57.3, 0, 149, tree2],
			[39.3, 0, 74.6, tree1],
			[171.3, 0, 162.8, tree1],
			[218.9, 0, 28.4, tree1],
			[126.7, 0, 21.7, tree2],
			[216.4, 0, 65.6, tree2],
			[25.4, 0, 6, tree2],
			[26.4, 0, 28.6, tree1],
			[150.2, 0, 101.3, tree1],
			[41.6, 0, 81.2, tree2],
			[224, 0, 11.7, tree1],
			[194.8, 0, 11.4, tree2],
			[24, 0, 165.8, tree2],
			[71.3, 0, 154.9, tree2],
			[174.4, 0, 30.4, tree2],
			[9.2, 0, 144.6, tree2],
			[99.9, 0, 38, tree2],
			[129.3, 0, 169.3, tree2],
			[61.1, 0, 59.8, tree1],
			[149.1, 0, 118.1, tree2],
			[201.5, 0, 146.3, tree2],
			[173.6, 0, 148.1, tree2],
			[58.1, 0, 58.2, tree1],
			[112.8, 0, 144.2, tree2],
			[135, 0, 30, tree2],
			[90.9, 0, 67.6, tree2],
			[108.5, 0, 154.9, tree2],
			[135, 0, 114.7, tree2],
			[58.9, 0, 113.9, tree2],
			[85.6, 0, 163, tree2],
			[216.4, 0, 78.2, tree2],
			[227.2, 0, 39.8, tree1],
			[65.4, 0, 133.2, tree1],
			[176.2, 0, 40.7, tree2],
			[58, 0, 74.8, tree2],
			[179, 0, 95.4, tree1],
			[92.6, 0, 96.9, tree2],
			[65.4, 0, 104.1, tree2],
			[134, 0, 144.3, tree1],
			[70.5, 0, 119.3, tree1],
			[202.8, 0, 100.9, tree2],
			[203, 0, 158.8, tree1],
			[200.5, 0, 24.8, tree2],
			[103.8, 0, 10.7, tree2],
		];

		// for (let i = 0; i < 60; i++) {
		// 	trees.push([
		// 		Number((Math.random() * 240).toFixed(1)),
		// 		0,
		// 		Number((Math.random() * 160 + 8).toFixed(1)),
		// 		Math.random() > 0.5 ? tree1 : tree2,
		// 	]);
		// }

		// console.log('Trees: ', trees);

		trees.forEach((tree) => {
			const pos = { x: tree[0], y: tree[1], z: tree[2] };
			Tree.createOne(pos);
		});
	}

	static createOne(position) {
		const trunkDiameter = 0.4 + Math.random() * 0.3;
		const trunkHeight = 0.3 + Math.random() * 2;
		pos1.set(position.x, trunkHeight / 2 - 0.01, position.z);
		const trunk = Tree.createTrunk(pos1, trunkDiameter, trunkHeight);

		// trunk.userData.body.setRestitution(1);
		// trunk.userData.body.setFriction(5);

		// console.log(trunk);

		const foliageWidth = 2 + Math.random() * 1;
		const foliageHeight = 2 + Math.random() * 2;
		pos2.set(position.x, trunkHeight + foliageHeight / 2, position.z);
		const foliage = Tree.createFoliage(pos2, foliageWidth, foliageHeight);

		// Anchor together
		const pivotA = new AP.Ammo.btVector3(0, trunkHeight / 2, 0);
		const pivotB = new AP.Ammo.btVector3(0, -foliageHeight / 2, 0);
		const axis = new AP.Ammo.btVector3(1, 1, 1);
		const hinge = new AP.Ammo.btHingeConstraint(
			trunk.userData.body,
			foliage.userData.body,
			pivotA,
			pivotB,
			axis,
			axis,
			true
		);
		AP.world.addConstraint(hinge, true);
	}

	static createTrunk(pos, d, h) {
		if (Math.random() < 0.5) {
			return Shapes.cylinder(d / 2, h, pos, null, brown, 0, 2);
		} else {
			return Shapes.box(d, h, d, pos, null, brown, 0, 2);
		}
	}

	static createFoliage(pos, w, h) {
		const rnd = Math.floor(Math.random() * 3);
		if (rnd === 0) {
			return Shapes.box(w, h, w, pos, null, green, 5, 2);
		} else if (rnd === 1) {
			return Shapes.cone(w / 2, h, pos, null, green, 5, 2);
		} else if (rnd === 2) {
			return Shapes.cylinder(w / 2, h, pos, null, green, 5, 2);
		} else if (rnd === 3) {
			return Shapes.sphere(w * (3 / 4), pos, null, green, 5, 2);
		} else if (rnd === 4) {
			return Shapes.pyramid4(w * 2, h, pos, null, green, 5, 2);
		}
	}
}
