import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { MeshPhongMaterial, Quaternion, Vector3 } from 'three';
import { Shapes } from '../geometries/Shapes';

import wordSteve from './words/steve.json';
import wordRichardson from './words/richardson.json';

const rad = (deg) => deg * (Math.PI / 180);
const axisOfRotation = new Vector3(0, 1, 0);

const white = new MeshPhongMaterial({
	color: 0xeeeeee,
	specular: 0xaaaaff,
	shininess: 0,
});
const orange = new MeshPhongMaterial({
	color: 0xffaa00,
	specular: 0xaaaaff,
	shininess: 0,
});

export class Text {
	static loader = new FontLoader();

	static init() {
		Text.fullName();
		Text.controls();

		// RUN THIS TO GENERATE CONFIG
		// Text.individualLettersGenerateConfig({
		// 	text: 'RICHARDSON',
		// 	origin: new Vector3(22, 0, 8),
		// 	flat: false,
		// 	size: 2,
		// 	material: orange,
		// 	rotateY: -150,
		// });

		// Text.individualLettersGenerateConfig({
		// 	text: 'STEVE',
		// 	origin: new Vector3(22, 2.1, 8),
		// 	flat: false,
		// 	size: 2,
		// 	material: white,
		// 	rotateY: -150,
		// });
	}

	static createText({
		text,
		origin,
		flat,
		size,
		rotateY,
		material: mat,
		direction,
	}) {
		const dir = new Vector3();
		const quat = new Quaternion();

		quat.setFromAxisAngle(axisOfRotation, rad(rotateY));
		dir.set(direction.x, direction.y, direction.z);

		return new Promise((resolve, reject) => {
			Text.loader.load('fonts/coiny_regular.typeface.json', function (font) {
				const geo = new TextGeometry(text, {
					font: font,
					size: size,
					height: size / 2,
					curveSegments: 12,
					bevelThickness: size / 100,
					bevelSize: size / 40,
					bevelEnabled: true,
				});

				geo.computeBoundingBox();

				const { boundingBox } = geo;
				let w = boundingBox.max.x - boundingBox.min.x;
				w = w === -Infinity ? size * 0.5 : w; // Handle spaces

				let h = boundingBox.max.y - boundingBox.min.y;
				h = h === -Infinity ? size * 0.5 : h; // Handle spaces

				let d = boundingBox.max.z - boundingBox.min.z;
				d = d === -Infinity ? size * 0.5 : d; // Handle spaces

				// Add offset of half letter width + kerning
				const offset = dir.clone().multiplyScalar(w / 2);
				const kerning = dir.clone().multiplyScalar(size / 10);
				origin.add(offset).add(kerning);

				const params = [w, h, d, geo, origin, quat, mat, 50, 1.1];
				const mesh = Shapes.text(...params);

				mesh.castShadow = true;
				mesh.receiveShadow = true;

				resolve(w);
			});
		});
	}

	static individualLettersGenerateConfig(props) {
		// Text.individualLetters({
		// 	text: 'STEVE',
		// 	origin: new Vector3(20, 0, 10),
		// 	flat: false,
		// 	size: 2,
		// 	material: orange,
		// 	rotateY: -150,
		// });

		const { text, origin } = props;

		const xPos = Math.cos(rad(props.rotateY)) * 1;
		const zPos = -Math.sin(rad(props.rotateY)) * 1;
		const direction = new Vector3(xPos, 0, zPos).normalize();

		const position = new Vector3().copy(origin);

		const word = [];

		const createLetters = async () => {
			const letters = text.split('');
			let lastWidth = 0;

			for (const letter of letters) {
				props.text = letter;
				props.origin = position;
				props.direction = { ...direction };

				word.push({
					text: letter,
					origin: { x: position.x, y: position.y, z: position.z },
					size: props.size,
					rotateY: props.rotateY,
					direction: { ...props.direction },
				});

				lastWidth = await Text.createText(props);

				const xPos = Math.cos(rad(props.rotateY)) * (lastWidth / 2);
				const zPos = -Math.sin(rad(props.rotateY)) * (lastWidth / 2);

				position.setX(position.x + xPos);
				position.setZ(position.z + zPos);
			}

			console.log(word);
		};
		createLetters();
	}

	static individualLettersFromConfig(wordConfig, material) {
		wordConfig.forEach((letter) => {
			const { origin } = letter;
			letter.origin = new Vector3(origin.x, origin.y, origin.z);
			letter.material = material;
			Text.createText(letter);
		});
	}

	static fullName() {
		// ADD RICHARDSON
		Text.individualLettersFromConfig(wordRichardson, orange);

		// ADD PLANK
		const quat = new Quaternion();
		quat.setFromAxisAngle(axisOfRotation, rad(-150));

		// Plank
		const brown = new MeshPhongMaterial({ color: 0x604540 });
		const plankPos = new Vector3(17.7, 2.1, 10.5);
		Shapes.box(10, 0.1, 1.4, plankPos, quat, brown, 30, 2);

		// Disable gravity of plank until letters print
		// const { body } = mesh.userData;
		// body.setGravity(new AP.Ammo.btVector3(0, 0, 0));
		// setTimeout(() => {
		// body.setGravity(new AP.Ammo.btVector3(0, AP.gravity, 0));
		// ADD STEVE
		Text.individualLettersFromConfig(wordSteve, white);
		// }, 3000);
	}

	static controls() {
		// Text.individualLettersFromConfig(wordSteve, white);
		// Text.individualLettersFromConfig(wordRichardson, orange);

		const material = new MeshPhongMaterial({ color: 0xfbd888 });

		const words = [
			{ text: 'CAMERA: C', pos: [1, 0, 0], size: 0.4 },
			{ text: 'SHOOT: SPACE', pos: [0, 0, 1.8], size: 0.4 },
			{ text: 'MOVE: WASD', pos: [4, 0, 1], size: 0.4 },
			{ text: 'UP / DOWN: I / K', pos: [4, 0, 3], size: 0.4 },
			{ text: 'CONTROLS', pos: [4, 0, 4.5], size: 1 },
		];

		words.forEach((word) => {
			Text.createText({
				text: word.text,
				origin: new Vector3(...word.pos),
				flat: false,
				size: word.size,
				rotateY: 180,
				material,
				direction: { x: -1, y: 0, z: 0 },
			});
		});
	}
}
