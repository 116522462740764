import { MeshStandardMaterial, Vector3 } from 'three';
import { roadGrid } from './road.grid';
import { Shapes } from '../geometries/Shapes';

const pos1 = new Vector3();
const pos2 = new Vector3();
const gutter = new MeshStandardMaterial({ color: 0x9b9a97 });
const bitumen = new MeshStandardMaterial({ color: 0x222222 });
const line = new MeshStandardMaterial({ color: 0xffff44 });

const roadWidth = 8;
const roadThickness = 0.1;
const gutterHeight = 0.2;
const tLine = 0.001; // line thickness
const wLine = 0.1; // line width

export class Road {
	static createRoads() {
		Road.placeFromConfigFile();
	}

	static placeFromConfigFile() {
		const gridPos = new Vector3();

		roadGrid.forEach((piece, i) => {
			const { dir } = piece;

			if (dir === '-z') piece.rotation = 0;
			if (dir === '+x') piece.rotation = 90;
			if (dir === '+z') piece.rotation = 180;
			if (dir === '-x') piece.rotation = 270;

			const x = roadWidth * piece.x;
			const z = roadWidth * piece.z;
			gridPos.set(x, 0, z);

			Road.createRoad(gridPos, piece);
		});
	}

	static createRoad(pos, piece) {
		// Place bitumen
		Road.createBitumen(...pos, piece);

		// Line
		Road.createLines(...pos, piece);

		// Place gutter
		Road.createGutters(...pos, piece);
	}

	static createBitumen(x, y, z) {
		const offset = roadThickness / 2 - 0.01;
		pos1.set(x, y - offset, z);
		const dims = [roadWidth, roadThickness, roadWidth];

		const mesh = Shapes.box(...dims, pos1, null, bitumen);
		mesh.name = 'bitumen';
	}

	static createLines(x, y, z, { rotation, type, flip, end }) {
		const lLine = roadWidth / 2; // line length

		const verticalOffset = roadThickness / 2;
		pos1.set(x, y + verticalOffset, z);

		const incY = 0;
		const posX = x + lLine / 4;
		const negX = x - lLine / 4;
		const posZ = z + lLine / 4;
		const negZ = z - lLine / 4;

		let dims1, dims2;

		if (type === 'I') {
			if ([90, 270].includes(rotation)) {
				dims1 = [lLine, tLine, wLine];
			} else {
				dims1 = [wLine, tLine, lLine];
			}
		} else if (type === 'L') {
			if (rotation === 90) {
				pos1.set(negX, incY, z);
				pos2.set(x, incY, posZ);
			} else if (rotation === 180) {
				pos1.set(negX, incY, z);
				pos2.set(x, incY, negZ);
			} else if (rotation === 270) {
				pos1.set(posX, incY, z);
				pos2.set(x, incY, negZ);
			} else {
				pos1.set(posX, incY, z);
				pos2.set(x, incY, posZ);
			}
			dims1 = [lLine / 2, tLine, wLine];
			dims2 = [wLine, tLine, lLine / 2];
		} else if (type === 'T' && !end) {
			if (rotation === 90) {
				pos1.set(x, incY, z);
				pos2.set(x, incY, flip ? negZ : posZ);
			} else if (rotation === 180) {
				pos1.set(flip ? posX : negX, incY, z);
				pos2.set(x, incY, z);
			} else if (rotation === 270) {
				pos1.set(x, incY, z);
				pos2.set(x, incY, flip ? posZ : negZ);
			} else {
				pos1.set(flip ? negX : posX, incY, z);
				pos2.set(x, incY, z);
			}

			dims1 = [lLine / 2, tLine, wLine];
			dims2 = [wLine, tLine, lLine / 2];
		} else if (type === 'T' && end) {
			if (rotation === 90) {
				pos1.set(negX, incY, z);
				pos2.set(x, incY + 0.01, z);
			} else if (rotation === 180) {
				pos1.set(x, incY, z);
				pos2.set(x, incY + 0.01, negZ);
			} else if (rotation === 270) {
				pos1.set(posX, incY, z);
				pos2.set(x, incY + 0.01, z);
			} else {
				pos1.set(x, incY, z);
				pos2.set(x, incY + 0.01, posZ);
			}

			dims1 = [lLine / 2, tLine, wLine];
			dims2 = [wLine, tLine, lLine / 2];
		}

		if (dims1) Road.createLine(dims1, pos1);
		if (dims2) Road.createLine(dims2, pos2);
	}

	static createLine(dims, pos) {
		const mesh = Shapes.box(...dims, pos, null, line);
		mesh.name = 'line';
	}

	static createGutters(x, y, z, { rotation, type, flip, end }) {
		const gh = gutterHeight;
		const hgh = gutterHeight / 2;
		const rw = roadWidth;
		const hrw = roadWidth / 2;

		// Standard
		const dims1Short = [gh, gh, rw - gh * 2];
		// Rotated
		const dims2Short = [rw - gh * 2, gh, gh];

		if (type === 'I') {
			if ([90, 270].includes(rotation)) {
				Road.createGutter(x, y, z + hrw + hgh, dims2Short); // Right
				Road.createGutter(x, y, z - hrw - hgh, dims2Short); // Left
			} else {
				Road.createGutter(x + hrw + hgh, y, z, dims1Short); // Right
				Road.createGutter(x - hrw - hgh, y, z, dims1Short); // Left
			}
		} else if (type === 'L') {
			const negX = [x - hrw - hgh, y, z, dims1Short];
			const posX = [x + hrw + hgh, y, z, dims1Short];
			const negZ = [x, y, z - hrw - hgh, dims2Short];
			const posZ = [x, y, z + hrw + hgh, dims2Short];

			if (rotation === 90) {
				Road.createGutter(...posX);
				Road.createGutter(...negZ);
			} else if (rotation === 180) {
				Road.createGutter(...posX);
				Road.createGutter(...posZ);
			} else if (rotation === 270) {
				Road.createGutter(...negX);
				Road.createGutter(...posZ);
			} else {
				Road.createGutter(...negX);
				Road.createGutter(...negZ);
			}
		} else if (type === 'T' && !end) {
			const negX = [x - hrw - hgh, y, z, dims1Short];
			const posX = [x + hrw + hgh, y, z, dims1Short];
			const negZ = [x, y, z - hrw - hgh, dims2Short];
			const posZ = [x, y, z + hrw + hgh, dims2Short];

			if (rotation === 90) {
				const pos = flip ? posZ : negZ;
				Road.createGutter(...pos);
			} else if (rotation === 180) {
				const pos = flip ? negX : posX;
				Road.createGutter(...pos);
			} else if (rotation === 270) {
				const pos = flip ? negZ : posZ;
				Road.createGutter(...pos);
			} else {
				const pos = flip ? posX : negX;
				Road.createGutter(...pos);
			}
		} else if (type === 'T' && end) {
			const negX = [x - hrw - hgh, y, z, dims1Short];
			const posX = [x + hrw + hgh, y, z, dims1Short];
			const negZ = [x, y, z - hrw - hgh, dims2Short];
			const posZ = [x, y, z + hrw + hgh, dims2Short];

			if (rotation === 90) {
				const pos = flip ? negX : posX;
				Road.createGutter(...pos);
			} else if (rotation === 180) {
				const pos = flip ? negZ : posZ;
				Road.createGutter(...pos);
			} else if (rotation === 270) {
				const pos = flip ? posX : negX;
				Road.createGutter(...pos);
			} else {
				const pos = flip ? posZ : negZ;
				Road.createGutter(...pos);
			}
		}
	}

	static createGutter(x, y, z, dims) {
		pos1.set(x, y, z);
		const mesh = Shapes.box(...dims, pos1, null, gutter);
		mesh.name = 'gutter';
	}
}
