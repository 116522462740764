import {
	Clock,
	DodecahedronGeometry,
	Group,
	Mesh,
	MeshStandardMaterial,
} from 'three';
import { World } from './World';

const clock = new Clock();

const white = new MeshStandardMaterial({ color: 0xffffff });

const cloudGeo1 = new DodecahedronGeometry(6);
const cloudGeo2 = new DodecahedronGeometry(4);
const cloudGeo3 = new DodecahedronGeometry(2);

// CLOUD GROUP 1
const cloudGroup1 = new Group();
const cloudMesh1 = new Mesh(cloudGeo1, white);
cloudMesh1.castShadow = true;

const cloudMesh2 = new Mesh(cloudGeo2, white);
cloudMesh2.position.set(4.5, -2, 0.2);
cloudMesh2.castShadow = true;

const cloudMesh3 = new Mesh(cloudGeo3, white);
cloudMesh3.position.set(7.5, -1, 0.4);
cloudMesh3.castShadow = true;

cloudGroup1.add(cloudMesh1, cloudMesh2, cloudMesh3);

// CLOUD GROUP 2
const cloudGroup2 = cloudGroup1.clone();
const cloudMesh4 = cloudMesh2.clone();
cloudMesh4.position.set(-4.5, -2, 0.2);
const cloudMesh5 = cloudMesh3.clone();
cloudMesh5.position.set(-7.5, -1, 0.4);
cloudGroup2.add(cloudMesh4, cloudMesh5);

// Clouds
const cloud1 = cloudGroup1.clone();
cloud1.position.set(25, 50, 20);
cloud1.castShadow = true;

const cloud2 = cloudGroup2.clone();
cloud2.position.set(85, 45, 10);
cloud2.castShadow = true;

const cloud3 = cloudGroup2.clone();
cloud3.position.set(185, 40, 120);

export class Clouds {
	static init() {
		World.scene.add(cloud1, cloud2, cloud3);

		World.addAnimationCallback(Clouds.animate);
	}

	static animate() {
		const elapsedTime = clock.getElapsedTime();

		const cloud1Angle = (elapsedTime / 60) * 0.5;
		cloud1.position.x = Math.cos(cloud1Angle) * 100 + 120;
		cloud1.position.z = Math.sin(cloud1Angle) * 100 + 88;
		cloud1.rotateY(0.0001);

		const cloud2Angle = (elapsedTime / 60) * 0.5 + 3;
		cloud2.position.x = Math.cos(cloud2Angle) * 80 + 120;
		cloud2.position.z = -Math.sin(2 * cloud2Angle) * 40 + 88;
		cloud2.rotateY(0.0002);

		const cloud3Angle = (elapsedTime / 60) * 0.5 - 3;
		cloud3.position.x = Math.cos(3 * cloud3Angle) * 70 + 120;
		cloud3.position.z = Math.sin(2 * cloud3Angle) * 70 + 88;
		cloud3.rotateY(0.0003);
	}
}
