import {
	ConeGeometry,
	Mesh,
	MeshPhongMaterial,
	Quaternion,
	SphereGeometry,
	Vector3,
} from 'three';
import { Shapes } from '../geometries/Shapes';
import { World } from './World';

const pos = new Vector3();
const quat = new Quaternion();

const green = new MeshPhongMaterial({ color: 0x55aa55, shininess: 0 });
const brown = new MeshPhongMaterial({
	color: 0x71392a,
	shininess: 0,
});

const origin = new Vector3(20, -10, -30);

const groundOutline = [
	{ x: 0, y: 0, z: 0 },
	{ x: -8, y: 0, z: -10 },
	{ x: -10, y: 0, z: -20 },
	{ x: 0, y: 0, z: -28 },
	{ x: 10, y: 0, z: -30 },
	{ x: 20, y: 0, z: -20 },
	{ x: 18, y: 0, z: -3 },
];

const r = 170;
const hGrass = 1;

export class Island {
	static init() {
		pos.set(20, -hGrass / 2, -30);

		Island.grass();
		Island.earth();
		// Island.downwardConeRocks();
		// Island.outline();
	}

	static grass() {
		// const shape = new Shape();

		// shape.moveTo(groundOutline[0].x, groundOutline[0].z);

		// for (let i = 1; i < groundOutline.length; i++) {
		// 	shape.lineTo(groundOutline[i].x, groundOutline[i].z);
		// }

		// const extrudeSettings = {
		// 	steps: 2,
		// 	depth: hGrass,
		// 	bevelEnabled: true,
		// 	bevelThickness: 0,
		// 	bevelSize: 0,
		// 	bevelOffset: 0,
		// 	bevelSegments: 0,
		// };

		// const geometry = new ExtrudeGeometry(shape, extrudeSettings);
		// geometry.rotateX(Math.PI / 2);
		// Shapes.convexHull(origin, quat, geometry, green, 0);

		quat.set(1, 0, 0, 0).normalize();
		pos.copy(origin).add(new Vector3(5, -4 - hGrass, -15));
		Shapes.pyramid(16.5, 10, 6, pos, quat, green, 0);
	}

	static earth() {
		quat.set(1, 0, 0, 0).normalize();
		pos.copy(origin).add(new Vector3(5, -5 - hGrass, -15));
		Shapes.pyramid(15, 10, 6, pos, quat, brown, 0);

		// let pts = [];
		// const positions = [...groundOutline];
		// positions.push(groundOutline[0]);
		// for (let i = 0; i < groundOutline.length; i++) {
		// 	const pt1 = positions[i + 1];
		// 	const pt2 = positions[i];
		// 	const pt3 = { x: origin.x, y: origin.y - 10, z: origin.z };

		// 	pts.push(pt1.x, pt1.y - 0.5 * hGrass, pt1.z);
		// 	pts.push(pt2.x, pt2.y - 0.5 * hGrass, pt2.z);
		// 	pts.push(pt3.x, pt3.y - 0.5 * hGrass, pt3.z);
		// }

		// const vertices = new Float32Array(pts);
		// geometry.setAttribute('position', new BufferAttribute(vertices, 3));
		// geometry.computeVertexNormals();
		// const mesh = new Mesh(geometry, brown);

		// World.scene.add(mesh);
	}

	static downwardConeRocks() {
		// t is from azimuth, ~ 0.75~1
		// s is from back, between 0 and 1

		const coords = [
			{ s: 0.0, t: 0.9, radius: r / 8, h: 40, segs: 3, up: 90 },
			{ s: 0.35, t: 0.9, radius: r / 8, h: 40, segs: 3, up: 100 },
			{ s: 0.65, t: 0.9, radius: r / 8, h: 50, segs: 3, up: 110 },
			{ s: 0.85, t: 0.9, radius: r / 8, h: 50, segs: 3, up: 90 },
			{ s: 0.5, t: 0.82, radius: r / 8, h: 40, segs: 3, up: 100 },
			{ s: 0.62, t: 0.8, radius: r / 8, h: 40, segs: 3, up: 100 },
			{ s: 0.1, t: 0.8, radius: r / 5, h: 40, segs: 3, up: 101 },
			{ s: 0.25, t: 0.85, radius: r / 5, h: 40, segs: 3, up: 117 },
			{ s: 0.45, t: 0.82, radius: r / 3, h: 45, segs: 3, up: 112 },
			{ s: 0.45, t: 0.95, radius: r / 3, h: 45, segs: 3, up: 90 },
			{ s: 0.9, t: 0.8, radius: r / 3, h: 45, segs: 3, up: 110 },
			{ s: 0.55, t: 0.8, radius: r / 3, h: 45, segs: 3, up: 110 },
			{ s: 0.55, t: 1, radius: r / 3, h: 65, segs: 3, up: 90 },
			{ s: 0, t: 0.75, radius: r / 3, h: 65, segs: 3, up: 90 },
		];

		coords.forEach((coord, i) => {
			const { radius, h, segs, up } = coord;
			const s = 2 * Math.PI * coord.s;
			const t = Math.PI * coord.t;
			const x = r * Math.cos(s) * Math.sin(t);
			const y = r * Math.cos(t);
			const z = r * Math.sin(s) * Math.sin(t);

			const geometry = new ConeGeometry(radius, h, segs, 1, false);
			const rockMesh = new Mesh(geometry, brown);
			// i === coords.length - 1
			// 	? new MeshPhongMaterial({ color: 0x91ff2a, shininess: 0 })
			// 	: brown
			rockMesh.position.set(x, y, z).add(pos);
			rockMesh.translateY(-hGrass + up);
			rockMesh.rotateX(Math.PI);
			World.scene.add(rockMesh);
		});
	}

	static outline() {
		const yellow = new MeshPhongMaterial({ color: 0xffff00, shininess: 0 });
		groundOutline.forEach((coord, i) => {
			const m = new Mesh(new SphereGeometry(1, 16, 16), yellow);
			m.position.set(coord.x, hGrass, coord.z);
			World.scene.add(m);
			const folder = World.dat.addFolder(i);
			folder
				.add(m.position, 'x', -50, 300)
				.listen()
				.onChange(() => {
					console.log(groundOutline);
				});
			folder
				.add(m.position, 'z', -90, 250)
				.listen()
				.onChange(() => {
					console.log(groundOutline);
				});
		});
	}
}
