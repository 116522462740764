import {
	Vector3,
	MeshPhongMaterial,
	Quaternion,
	Mesh,
	SphereGeometry,
	CylinderGeometry,
	BoxGeometry,
} from 'three';
import { Shapes } from '../geometries/Shapes';
import { AP } from '../scene/AmmoPhysics';

import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { World } from '../scene/World';

const white = new MeshPhongMaterial({ color: 0xeeeeee });
const blue = new MeshPhongMaterial({ color: 0x63aefc });

const position = new Vector3();

let rotorHinges = [];

export class Windmill {
	static init() {
		const origins = [
			new Vector3(20, 0, 80),
			new Vector3(150, 0, 150),
			new Vector3(200, 0, 50),
			new Vector3(24, -10, -45),
		];

		origins.forEach((origin) => {
			// Create pylon
			const { totalHeight } = Windmill.createPylon(origin);

			// Turbine
			const { turbine } = Windmill.createTurbine(origin, totalHeight);

			// Rotor
			const { rotor, rRotor } = Windmill.createRotor({ turbine });

			// Add blue dome to rotor
			rotor.add(new Mesh(new SphereGeometry(rRotor, 16, 16), blue));
		});

		World.addAnimationCallback(Windmill.animate);
	}

	static createPylon(origin) {
		// Define levels
		const levels = [
			{ radius: 1, height: 10, material: white },
			{ radius: 1, height: 0.5, material: blue },
			{ radius: 0.7, height: 7, material: white },
			{ radius: 0.7, height: 0.5, material: blue },
			{ radius: 0.5, height: 5, material: white },
		];

		// Helper function to get total height of pylon
		const totalHeight = levels.reduce((p, c) => p + c.height, 0);

		// Create pylon segments
		let runningTotal = 0;
		levels.forEach((level) => {
			const { radius, height, material } = level;
			const y = origin.y + runningTotal + height / 2;

			position.set(origin.x, y, origin.z);
			Shapes.cylinder(radius, height, position, null, material, 0);

			runningTotal += height;
		});

		return { totalHeight };
	}

	static createTurbine(origin, totalHeight) {
		// Turbine
		const lTurbine = 6;
		const rTurbine = 0.7;
		const turbPos = new Vector3(origin.x, origin.y + totalHeight, origin.z);

		const turbineRot = new Quaternion(0.5, 0, 0, 0.5).normalize();
		const turbine = Shapes.cylinder(
			rTurbine,
			lTurbine,
			turbPos,
			turbineRot,
			white,
			0
		);

		const trimLength = 0.3;
		const trimOffset = -lTurbine / 2 - trimLength / 2;
		const trimPos = turbPos.clone().add(new Vector3(0, 0, trimOffset));
		Shapes.cylinder(rTurbine, trimLength, trimPos, turbineRot, blue, 0);

		return { turbine, rTurbine, lTurbine };
	}

	static createRotor({ turbine }) {
		const {
			btVector3,
			btHingeConstraint,
			btCylinderShape,
			btBoxShape,
			btTransform,
			btCompoundShape,
		} = AP.Ammo;

		// Turbine params
		const rTurbine = turbine.geometry.parameters.radiusTop;
		const lTurbine = turbine.geometry.parameters.height;

		// Blade params
		const lBlade = 25;
		const wBlade = 1;
		const rRotor = rTurbine * 1.1;
		const lRotor = 1;

		const rotorPosOffset = new Vector3(0, 0, lTurbine / 2 + lRotor / 2);
		const rotorPos = turbine.position.clone().add(rotorPosOffset);

		// Create rotor shape
		const rotorGeo = new CylinderGeometry(rRotor, rRotor, lRotor, 32, 1);
		const rotorDims = new btVector3(rRotor, lRotor / 2, rRotor);
		const rotorShape = new btCylinderShape(rotorDims);

		// Create blae shape
		const bladeGeo = new BoxGeometry(wBlade, 0.1, lBlade);
		const bladeDims = new btVector3(wBlade / 2, 0.1 / 2, lBlade / 2);
		const bladeShape = new btBoxShape(bladeDims);

		// Create compound shape
		const transform = new btTransform();
		const compound = new btCompoundShape();

		// Add rotor to compound shape
		transform.setIdentity();
		compound.addChildShape(transform, rotorShape);

		// Add blade to compound shape
		transform.setIdentity();
		compound.addChildShape(transform, bladeShape);

		// Convert to nonIndexed for merge
		const geometry = BufferGeometryUtils.mergeBufferGeometries([
			rotorGeo,
			bladeGeo,
		]);

		// Create final rotor and set position
		const rotor = new Mesh(geometry, white);
		rotor.position.copy(rotorPos);

		// Add to physics world
		AP.handleMesh(rotor, 5, compound);
		World.scene.add(rotor);

		// Hinge rotor to turbine
		const rotorBody = rotor.userData.body;
		const turbineBody = turbine.userData.body;
		const rotorAxis = new btVector3(0, 1, 0);
		const turbPivot = new btVector3(0, lTurbine / 2, 0);
		const rotorPivot = new btVector3(0, -lRotor / 2, 0);

		// Create hinge and add to physics world
		const rotorHinge = new btHingeConstraint(
			turbineBody,
			rotorBody,
			turbPivot,
			rotorPivot,
			rotorAxis,
			rotorAxis,
			true
		);
		AP.world.addConstraint(rotorHinge, true);

		rotorHinges.push(rotorHinge);

		return { rotor, rRotor };
	}

	static animate() {
		rotorHinges.forEach((rotorHinge) => {
			if (!rotorHinge) return;
			rotorHinge.enableAngularMotor(true, 1, 50);
		});
	}
}
