import { MeshPhongMaterial, Quaternion, Vector3 } from 'three';
import { Shapes } from '../geometries/Shapes';

const pos = new Vector3();
const quat = new Quaternion();

const center = new Vector3(120, 0, 88);

const rad = (deg) => deg * (Math.PI / 180);

const material = new MeshPhongMaterial({
	color: 0xffff77,
	shininess: 0,
	reflectivity: 1,
	transparent: true,
	opacity: 0.8,
});

export class Coliseum {
	static init() {
		const w = 9;
		const h = 3;
		const d = 2;

		let radius = 20;
		const degInc = 30;
		const axisOfRotation = new Vector3(0, 1, 0);

		const levels = 15;

		const startColiseumEffect = 5;
		let removeCount = 0;

		let alternate = false;

		for (let level = 0; level < levels; level++) {
			const height = level * (h + 0.01) + 0.5 * h;
			alternate = !alternate;
			if (level >= startColiseumEffect) removeCount += 1;

			for (let deg = 0; deg < 360; deg = deg + degInc) {
				const step = alternate ? deg : deg + degInc / 2;

				// Coliseum effect
				if (level > startColiseumEffect) {
					const bound = 0 + degInc * removeCount;
					if (deg <= bound && deg >= 360 - bound) continue;
				}

				// Set block rotation
				const angleOfRotation = 90 - step;
				quat.setFromAxisAngle(axisOfRotation, rad(angleOfRotation));

				const x = Math.cos(rad(step)) * radius;
				const y = Math.sin(rad(step)) * radius;
				pos.set(x, height, y).add(center);
				Shapes.box(w, h, d, pos, quat, material, 5, 10);
			}
		}

		// Cap
		// const capHeight = h * 5;
		// pos.set(0, levels * h + capHeight / 2, 0).add(center);
		// quat.setFromAxisAngle(new Vector3(1, 0, 0), Math.PI);

		// Shapes.cone(radius + w / 2, capHeight, pos, quat, material, 60, 0.5);
	}
}
