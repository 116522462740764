import {
	AmbientLight,
	Color,
	PerspectiveCamera,
	Scene,
	Vector3,
	WebGLRenderer,
	Object3D,
	DirectionalLight,
	PCFSoftShadowMap,
	// AxesHelper,
	Clock,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js';

import { Drone } from './Drone';
import { Ground } from './Ground';
import { Road } from '../structures/Road';
import { Tree } from '../structures/Tree';
import { Coliseum } from '../structures/Coliseum';
import { Text } from '../structures/Text';
import { Clouds } from './Clouds';
import { Windmill } from '../structures/Windmill';
import { Ballpit } from '../structures/Ballpit';
import { Island } from './Island';
import { Billboard } from '../structures/Billboard';
import { Moons } from './Moons';
import { StreetLights } from '../structures/StreetLights';

export class World {
	static renderer;
	static scene;
	static camera;
	static pointLight;
	static controls;
	static dat = new GUI({ width: 600 });
	static cameraRoaming = false;
	static animationFunctions = [];
	static clock = new Clock();

	static async init(containerId) {
		World.createCamera();
		World.createRenderer(containerId);
		World.createScene();
		World.lights();
		World.createControls();

		// Watch for window resize
		window.addEventListener('resize', World.onWindowResize);

		if (process.env.NODE_ENV === 'development') {
			// const ah = new AxesHelper(1000);
			// World.scene.add(ah);
		} else {
			this.dat.hide();
		}

		// Nature
		Ground.init();
		Island.init();
		Clouds.init();
		Moons.init();
		Tree.createMany();

		// Structures
		Road.createRoads();
		Coliseum.init();
		Windmill.init();
		Ballpit.init();
		Billboard.init();
		StreetLights.init();
		Text.init();

		Drone.init();

		World.animate();

		World.droneView();
	}

	static createControls() {
		const { camera, renderer } = World;

		// Create controls
		const ctrls = new OrbitControls(camera, renderer.domElement);

		ctrls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
		ctrls.dampingFactor = 0.05;
		ctrls.screenSpacePanning = false;
		// ctrls.minDistance = 100;
		// ctrls.maxDistance = 500;

		World.controls = ctrls;

		// Controls toggle
		document.addEventListener('keypress', (e) => {
			if (e.code === 'KeyC') {
				ctrls.enabled = !ctrls.enabled;
				World.toggleView();
			}
		});
	}

	static toggleView() {
		// Update controls
		// const { controls, camera } = World;

		// if (!controls || !Drone.drone) return;

		World.cameraRoaming ? World.droneView() : World.freeView();
		World.cameraRoaming = !World.cameraRoaming;

		// if (controls.enabled) {
		// 	// Remove camera from dronegroup
		// 	camera.removeFromParent();
		// 	World.scene.add(camera);
		// 	return;
		// } else {
		// 	// Add camera to dronegroup
		// 	Drone.droneGroup.add(camera);
		// }

		// const setback = new Vector3(0, 0.1, -1.3);
		// camera.position.set(setback.x, setback.y, setback.z);
	}

	static droneView() {
		const { camera, controls } = World;
		controls.enabled = false;

		Drone.droneGroup.add(World.camera);

		const setback = new Vector3(0, 0.1, -1.3);
		camera.position.set(setback.x, setback.y, setback.z);
	}

	static freeView() {
		const { scene, controls, camera } = World;

		camera.removeFromParent();
		scene.add(camera);

		// Set view
		// camera.position.set(-3, 3, -4);
		// controls.target = new Vector3(2, 0, 1);

		// Island
		// camera.position.set(14, 63, -36);
		// controls.target = new Vector3(17, 0, -36);

		// Billboard
		// camera.position.set(66, 8, 9);
		// controls.target = new Vector3(76, 4, -3);

		// Moon
		// camera.position.set(66, 8, 9);
		// controls.target = new Vector3(650, 50, 160);

		// StreetLight
		camera.position.set(-4, 9, -14);
		controls.target = new Vector3(20, 0, 15);
	}

	static lights() {
		const al = new AmbientLight(0xffffff, 0.6); // soft white light
		World.scene.add(al);

		// DIRECTIONAL LIGHT ==========================
		const sun = new DirectionalLight(0xffffff, 1);
		sun.position.set(430, 50, 140); // Moon position

		sun.castShadow = true;

		const breadth = 180;
		const width = 180;
		sun.shadow.camera.left = -width;
		sun.shadow.camera.right = width;
		sun.shadow.camera.top = breadth;
		sun.shadow.camera.bottom = -breadth;

		sun.shadow.camera.near = 1;
		sun.shadow.camera.far = 660;
		sun.shadow.mapSize.set(2048, 2048);
		sun.shadow.bias = -0.0005;
		sun.shadow.normalBias = 0.06;

		const target = new Object3D();
		target.position.set(100, 0, 78);
		sun.target = target;
		World.scene.add(target, sun);

		if (process.env.NODE_ENV === 'development') {
			// World.scene.add(new CameraHelper(sun.shadow.camera));
		}
		// ============================================

		const under = new DirectionalLight(0xffffff, 0.5);
		under.position.set(110, -170, 90);
		under.castShadow = true;

		under.shadow.camera.left = -width;
		under.shadow.camera.right = width;
		under.shadow.camera.top = breadth;
		under.shadow.camera.bottom = -breadth;

		under.shadow.camera.near = 1;
		under.shadow.camera.far = 160;
		under.shadow.mapSize.set(2048, 2048);

		under.target = target;
		World.scene.add(target, under);

		if (process.env.NODE_ENV === 'development') {
			// World.scene.add(new CameraHelper(under.shadow.camera));
		}
	}

	static createCamera() {
		const ratio = window.innerWidth / window.innerHeight;
		const camera = new PerspectiveCamera(75, ratio, 0.1, 1000);
		World.camera = camera;

		const folder = World.dat.addFolder('Camera');
		folder.add(camera.position, 'x', -50, 300).listen();
		folder.add(camera.position, 'y', 0, 300).listen();
		folder.add(camera.position, 'z', 0, 300).listen();
		folder.close();
	}

	static createRenderer(containerId) {
		const renderer = new WebGLRenderer({ antialias: true });
		renderer.setSize(window.innerWidth, window.innerHeight);
		renderer.shadowMap.enabled = true;
		renderer.shadowMap.type = PCFSoftShadowMap;
		// BasicShadowMap
		// PCFShadowMap
		// PCFSoftShadowMap
		// VSMShadowMap

		const container = document.getElementById(containerId);
		if (!container) return;
		container.appendChild(renderer.domElement);

		World.renderer = renderer;
	}

	static createScene() {
		const scene = new Scene();
		scene.background = new Color(0x7777dd);
		World.scene = scene;
		// World.scene.fog = new Fog(0xccccff, 200, 300);
	}

	static addAnimationCallback(fn) {
		if (typeof fn !== 'function') return;
		World.animationFunctions.push(fn);
	}

	static animate() {
		// Get delta
		const delta = World.clock.getDelta();
		const elapsed = World.clock.getElapsedTime();

		// Create loop
		setTimeout(World.animate, 1000 / 32);

		// Update drone model
		// Drone.animate();

		// // Update clouds
		// Clouds.animate();

		// Windmill.animate();

		// Call all animation function
		World.animationFunctions.forEach((fn) => fn(delta, elapsed));

		// Update camera to follow drone
		const { controls, camera } = World;
		const { drone, droneGroup } = Drone;

		if (!controls) return;

		if (!controls.enabled && drone) {
			camera.lookAt(droneGroup.position);
		} else {
			controls.update();
		}

		World.renderer.render(World.scene, World.camera);
	}

	static onWindowResize() {
		World.camera.aspect = window.innerWidth / window.innerHeight;
		World.camera.updateProjectionMatrix();

		World.renderer.setSize(window.innerWidth, window.innerHeight);
	}
}
