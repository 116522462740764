import { Mesh, MeshPhongMaterial, SphereGeometry, Vector3 } from 'three';
import { World } from '../scene/World';
import { Drone } from '../scene/Drone';
import { Portal } from './Portal';

const pos = new Vector3();

export class LinkSphere {
	insideCallback = null;
	droneInside = false;
	portal = null;

	constructor({ parent, insideCallback = null }) {
		this.insideCallback = insideCallback;

		// Get screen position
		pos.copy(parent.position);

		// Calculate positions
		const portalLocalPos = new Vector3(0, -7, 7);
		const sphereLocalPos = portalLocalPos.clone().add(new Vector3(0, 6, 0));

		// Create visual sphere geometry and material
		const sphere = new SphereGeometry(6, 8, 8);
		const material = new MeshPhongMaterial({
			color: 0xffffff,
			transparent: true,
			opacity: 0,
		});

		// Create visual mesh
		const sphereMesh = new Mesh(sphere, material);
		sphereMesh.position.copy(sphereLocalPos);
		sphereMesh.renderOrder = 1000;
		parent.add(sphereMesh);

		const sphereGlobalCenter = new Vector3();
		sphereMesh.getWorldPosition(sphereGlobalCenter);

		// Create portal
		this.portal = new Portal(parent, portalLocalPos);

		// Set up detection sphere using visual mesh
		sphereMesh.geometry.computeBoundingSphere();
		this.sphere = sphereMesh.geometry.boundingSphere;
		this.sphere.center.copy(sphereGlobalCenter);

		World.addAnimationCallback(this.animate);
	}

	animate = () => {
		const inside = this.sphere.containsPoint(Drone.droneGroup.position);

		if (!this.droneInside && inside) {
			if (this.insideCallback) {
				this.insideCallback(true);
			}

			this.portal.active();
			this.droneInside = true;
		} else if (this.droneInside && !inside) {
			if (this.insideCallback) {
				this.insideCallback(false);
			}

			this.portal.inactive();
			this.droneInside = false;
		}
	};
}
