import { Vector3, MeshPhongMaterial } from 'three';
import { Shapes } from '../geometries/Shapes';

// Pit
const origin = new Vector3(50, 0, 137);
const height = 1.5;
const length = 10;
const width = 8;
const thickness = 0.2;

// Balls
const ballCount = 100;
const ballRadius = 0.55;

export class Ballpit {
	static init() {
		Ballpit.createWalls();
		Ballpit.createBalls();
	}

	static createWalls() {
		const wall = new MeshPhongMaterial({ color: 0x5e2d0c });
		const stake = new MeshPhongMaterial({ color: 0xeacb68 });

		const { x, z } = origin;
		const pos1 = new Vector3(x - width / 2, height / 2, z);
		const pos2 = new Vector3(x + width / 2, height / 2, z);
		const pos3 = new Vector3(x, height / 2, z + length / 2);
		const pos4 = new Vector3(x, height / 2, z - length / 2);

		// Create arrays for spreading
		const dims1 = [null, wall, 0, 0.1];
		const dims2 = [thickness, height, length];
		const dims3 = [width, height, thickness];

		// Wall 1
		Shapes.box(...dims2, pos1, ...dims1);
		Shapes.box(...dims2, pos1, ...dims1).translateY(height * 1.1);

		// Wall 2
		Shapes.box(...dims2, pos2, ...dims1);
		Shapes.box(...dims2, pos2, ...dims1).translateY(height * 1.1);

		// Wall 3
		Shapes.box(...dims3, pos3, ...dims1);
		Shapes.box(...dims3, pos3, ...dims1).translateY(height * 1.1);

		// Wall 4
		Shapes.box(...dims3, pos4, ...dims1);
		Shapes.box(...dims3, pos4, ...dims1).translateY(height * 1.1);

		// Stakes
		const stakeWidth = 0.2;
		const stakeDims1 = [stakeWidth, height * 2 * 1.2, stakeWidth];
		const stakeDims2 = [null, stake, 0, 0.1];
		const tOffset = thickness / 2 + stakeWidth / 2;
		const hOffset = height / 2;
		const positions = [
			[pos1.x - tOffset, pos1.y + hOffset, pos1.z + length / 4],
			[pos1.x - tOffset, pos1.y + hOffset, pos1.z - length / 4],
			[pos2.x + tOffset, pos2.y + hOffset, pos2.z + length / 4],
			[pos2.x + tOffset, pos2.y + hOffset, pos2.z - length / 4],
			[pos3.x + width / 4, pos3.y + hOffset, pos3.z + tOffset],
			[pos3.x - width / 4, pos3.y + hOffset, pos3.z + tOffset],
			[pos4.x + width / 4, pos4.y + hOffset, pos4.z - tOffset],
			[pos4.x - width / 4, pos4.y + hOffset, pos4.z - tOffset],
		];

		const stakePos = new Vector3();
		positions.forEach((position) => {
			stakePos.set(position[0], position[1], position[2]);
			Shapes.box(...stakeDims1, stakePos, ...stakeDims2);
		});
	}

	static createBalls() {
		const dropPos = origin.clone().add(new Vector3(0, 1, 0));

		const materials = [
			new MeshPhongMaterial({ color: 0xff6666 }),
			new MeshPhongMaterial({ color: 0xaadd66 }),
			new MeshPhongMaterial({ color: 0x77ddee }),
			new MeshPhongMaterial({ color: 0xaaaaff }),
			new MeshPhongMaterial({ color: 0xdddd77 }),
		];

		for (let i = 0; i < ballCount; i++) {
			const x = (Math.random() - 0.5) * (0.9 * width);
			const y = Math.random() * 2;
			const z = (Math.random() - 0.5) * (0.9 * length);
			const pos = dropPos.clone().add(new Vector3(x, y, z));

			// Choose random material
			const randomNumber = Math.floor(Math.random() * 5);
			const mat = materials[randomNumber];

			setTimeout(() => {
				const ball = Shapes.sphere(
					ballRadius,
					pos,
					null,
					mat,
					0.1,
					0.06
				);
				const { body } = ball.userData;
				body.setRollingFriction(0.06);
				body.setRestitution(0.7);
			}, Math.random() * 3000);
		}
	}
}
